/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

const toggleSwitchContainerStyle = css`
    position: relative;
    width: 60px;
    display: inline-block;
    text-align: left;
`;

const toggleSwitchCheckboxStyle = css`
    display: none;

    &:checked + label .toggle-switch-inner {
        margin-left: 0;
    }

    &:checked + label .toggle-switch-switch {
        right: 0px;
    }
`;

const toggleSwitchLabelStyle = css`
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #999999;
    border-radius: 20px;
    margin: 0;
`;

const toggleSwitchInnerStyle = css`
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    background-color: #cccccc;

    &:before, &:after {
        display: block;
        float: left;
        width: 50%;
        height: 20px;
        padding: 0;
        line-height: 20px;
        font-size: 14px;
        color: white;
        box-sizing: border-box;
    }

    &:before {
        content: 'On';
        padding-left: 10px;
        background-color: #008E09;
        color: #ffffff;
    }

    &:after {
        content: 'Off';
        padding-right: 10px;
        background-color: #A8A8A8;
        color: #ffffff;
        text-align: right;
    }
`;

const toggleSwitchSwitchStyle = css`
    display: block;
    width: 18px;
    margin: 1px;
    background: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 35px;
    border: 2px solid #999999;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
`;

const toogleSwitchWrapper = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    
`

const textStyle = css`
    font-size: 12px;
    whitespace: nowrap;
`

const ToggleSwitch = ({ isOn, handleToggle, text }) => {
    return (
        <div css={toogleSwitchWrapper}>
            <label css={textStyle} htmlFor={`toggle-switch-new`}>{text}</label>
            <div css={toggleSwitchContainerStyle}>
                <input
                    css={toggleSwitchCheckboxStyle}
                    checked={isOn}
                    onChange={handleToggle}
                    id={`toggle-switch-new`}
                    type="checkbox"
                />
                <label css={toggleSwitchLabelStyle} htmlFor={`toggle-switch-new`}>
                    <span css={toggleSwitchInnerStyle} className="toggle-switch-inner" />
                    <span css={toggleSwitchSwitchStyle} className="toggle-switch-switch" />
                </label>
            </div>
        </div>
        
    );
};

export default ToggleSwitch;