/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const ErrorBoxStyle = css`

    display: flex;
    background-color: rgba(155,0,0,0.5);
    border: 1px solid rgba(155,0,0,0.9);
    padding: 10px;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;

`

const ErrorBox = ({ message }) => {
    return <div css={ErrorBoxStyle}>
            <div>😢</div>
            <div>{ message }</div>
        </div>
};

export default ErrorBox;