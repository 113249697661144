/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InfoSectionListStyle, InfoSectionStyle, InfoSectionHeadingStyle, DefaultBackground, InfoSectionHeadingStyleClose } from "../BarStyle.css";
import { ExplorerContext } from "../ExplorerContext";
import { Code } from "../Footer/Footer";
import { UilCircuit, UilTimes } from '@iconscout/react-unicons'


const getListIcon = (egw) => {
    if(egw.typeOf === "Hardware"){
        return UilCircuit
    }
    else {
        return Code
    }
}


const GatewaySelector = () => {

    const { hex, gwId } = useParams();
    const [data, setData] = useState();
    const {loadHexGateways, openInfoBar, setCurrentHex, currentHex, closeInfoBar} = useContext(ExplorerContext)
    const navigate = useNavigate({replace: true})

    const scrollToGwListSection = () => {
        const gwList = document.getElementById("gwList");
        if(!gwList) return
        gwList.scrollIntoView({behavior: "instant", block: "center", inline: "center"})
    }

    if(!gwId) scrollToGwListSection()

    useEffect(() => {
        if (!hex) return
        setData(null)
        setCurrentHex({index: hex, focus: !currentHex})
        loadHexGateways(hex, (d) => {
            const gws = d.data.gateway.gateways
            if(gws.length === 1){
                navigate(`/${hex}/${gws[0].id}`, { state: { passThrue: true } })
            }
            setData(gws)
            openInfoBar(true)
        
        })
        
    },[hex]) // eslint-disable-line react-hooks/exhaustive-deps


    const onClose = (e) => {
        e.preventDefault()
        closeInfoBar(false)
        setCurrentHex({hex: null, egw: null})
        navigate("/")
    }

    return <div className="innerScroll" id="gwList">
        <div>
            <div css={[InfoSectionStyle, InfoSectionHeadingStyle, DefaultBackground]}>                
                    <h2>Hex: {hex}</h2>
                    <a href="/" onClick={onClose} css={InfoSectionHeadingStyleClose}><UilTimes /></a>
            </div>
            <ul css={InfoSectionListStyle}>
                {data ? data.map((egw) => {
                    const ListIcon = getListIcon(egw)
                    return <li key={egw.id}>
                        <Link  
                            to={`/${hex}/${egw.id}`}
                            className={egw.id === gwId ? "active" : ""}
                            onClick={() => {
                                if(egw.id === gwId) 
                                    document.getElementById("gw").scrollIntoView({behavior: "instant", block: "center", inline: "center"})
                            }}
                        >
                            <h2>{egw.name} <ListIcon className={`${egw.typeOf}`} /></h2>
                            <span>{egw.id}</span>
                        </Link>
                    </li>
                }) : <SkeletonTheme baseColor="#374051" highlightColor="rgba(106, 134, 186,0.2)"> <Skeleton height={68} count={2} /> </SkeletonTheme>}
            </ul>
        </div>
    </div>
}


export default GatewaySelector