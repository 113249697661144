
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    TimeScale,
    BarElement
  } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { shouldUse24HourFormat, toTimeZoneDate } from '../DateHelpers';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    TimeScale,
    BarElement
  );

//as chartjs js will not print null we need to give it a value that we later can identify as null and paint red... we use 0.001337
//as only only two fractions are printed we can use 0.001337 as a value that will not be printed.
//im very sorry about this hack... it is what it is.
const numberOfDeath = -0.001337;


export const options = (tooltipFormat,startTime, timeZone) => { return {
    responsive: true,
    animation: false,
    cubicInterpolationMode: "monotone",
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                usePointStyle: true,
                pointStyle: 'circle',
                boxWidth: 10,  // Adjust the width of the colored box
                boxHeight: 10,
                font:{
                    size: 11,
                },
                generateLabels: function(chart) {
                    const datasets = chart.data.datasets;
                    if(datasets.length < 2) return [];
                    return datasets.map(dataset => ({
                        color: 'white',
                        text: dataset.label.toUpperCase(),
                        fillStyle: dataset.borderColor,  // Fill with border color
                        strokeStyle: dataset.borderColor,
                        lineWidth: 2,
                        fontColor: 'white',
                        hidden: !chart.isDatasetVisible(datasets.indexOf(dataset)),
                        datasetIndex: datasets.indexOf(dataset)
                    }));
                }
            }
        },
        tooltip:{
            callbacks:{
                label: function(context) {
                    if(context.raw === numberOfDeath) return "No data";
                    return context.formattedValue + " " + context.dataset.label+ " kW";
                },
                labelColor: function(context) {
                    if(context.raw === numberOfDeath) return { borderColor: "transparent", backgroundColor: "rgba(255, 122, 0, 0.8)" };
                    return { borderColor: context.dataset.pointBorderColor, backgroundColor: context.dataset.pointBackgroundColor };
                }
            }
        },
        offsetPlugin: {
            id: 'offsetPlugin',
            afterDatasetsDraw: (chart) => {
              chart.data.datasets.forEach((dataset, i) => {
                if (dataset.offset) {
                  const meta = chart.getDatasetMeta(i);
                  meta.data.forEach((dataPoint) => {
                    dataPoint.y -= 10005;
                  });
                }
              });
            }
          }
    },
    scales: {
        x: {
            type: 'time',
            min: toTimeZoneDate(new Date(startTime),timeZone),
            max: toTimeZoneDate(new Date(),timeZone),
            time: {
                tooltipFormat: tooltipFormat,
                displayFormats: { hour: shouldUse24HourFormat(timeZone) ? 'HH:mm' : 'h:mm a' },
            },
            beginAtZero: true,
            ticks:{
                color: "#FAF9F6",
            },
            grid: {
                display: false
            },
            border: {
                color: "transparent"
            }
        },
        y: {
            ticks:{
                color: "#FAF9F6",
                count: 5,
                format: { maximumFractionDigits: 2, minimumFractionDigits: 0 }
            },
            min: 0,
            border: {
                color: "black"
            }
        }
    }
  }};


const dataSetColors = {
    "hardware": {
        borderColor: 'rgba(255, 229, 0, 0.8)',
        backgroundColor: (context) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, "rgba(255, 229, 0, 0.6)");
            gradient.addColorStop(1, "rgba(255, 229, 0, 0.05)");
            return gradient;
        },
        pointBackgroundColor: 'rgba(173, 144, 36, 0.8)',
    },
    "software": {
        borderColor: 'rgba(1, 126, 122, 0.8)',
        backgroundColor: 'rgba(1, 126, 122, 0.5)',
        pointBackgroundColor: 'rgba(137, 176, 199, 0.8)',
    }
}


const ProductionGraph = ({datasets, type = "line", tooltipFormat="yyyy-MM-dd HH:mm", timeZone, startTime, colorMode, validationData}) => {

    const filteredDatasets = datasets.filter(x => x.length > 0);
    if(filteredDatasets.length === 0) return <></>;
    const labels = filteredDatasets[0].map(x => toTimeZoneDate(new Date(x.ts), timeZone));

    //DERS
    const dersData = filteredDatasets.map(d => 
        d.map(x => {
            if(x.power === null) return numberOfDeath;
            if(type === "line") return x.power/1000
            return x.power
        }
    ));
    //TOTAL
    const summedDataset = dersData[0].map((_, idx) => {
        return dersData.reduce((sum, current) => {
            return sum + current[idx];
        }, 0);
    });
    
    //API
    let validationDataSets =[]
    if(validationData && type === "line"){
        validationDataSets.push({
            label: "API",
            data: validationData.map(x => {
                if(x.power === null) return {x: x.ts, y: 0}
                return {x: toTimeZoneDate(new Date(x.ts),timeZone), y: x.power/1000};
                
            }),
            spanGaps: false,
            fill: false,
            pointBorderColor: 'transparent',
            pointRadius: 0,
            pointHoverRadius: 0,
            pointHitRadius: 0,
            borderWidth: 2,
            borderColor: 'rgba(112, 41, 99,0.8)',
        });
        
    }

    const ChartType = type === "line" ? Line : Bar;

    return <ChartType options={options(tooltipFormat,startTime, timeZone)} data={{
        labels: labels,
        datasets: [
            ...validationDataSets,
            //individual DER datasets
            ...dersData.length > 1 && type === "line" ? 
                dersData.map((d, i) => {
                    return {
                        label: "DER-"+(i+1),
                        data: d,
                        spanGaps: false,
                        fill:true,
                        borderColor: 'rgba(0, 255, 255, 0.5)',
                        backgroundColor: "transparent",
                        pointBorderColor: 'transparent',
                        borderWidth: 2,
                        pointRadius: 4,
                        pointStyle: false,
                        segment: {
                            borderColor: ctx => {
                                if(ctx.p0.raw === numberOfDeath && ctx.p1.raw === numberOfDeath) return "rgba(255, 122, 0, 0.8)";
                                return undefined;
                            }
                        }
                    }
                }) : 
                [],
            //sum of DERs datasets
            {
                label: "Total",
                data: summedDataset,
                spanGaps: false,
                fill:true,
                ...dataSetColors[colorMode],
                pointRadius: 4,
                pointStyle: false,
                segment: {
                    borderColor: ctx => {
                        if(ctx.p0.raw === numberOfDeath && ctx.p1.raw === numberOfDeath) return "rgba(255, 122, 0, 0.8)";
                        return undefined;
                    }
                }
            }
        ]
    }}  />

}



export default ProductionGraph;