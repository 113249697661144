

export const toTimeZoneDate = (date, timeZone) => {
    const convertedDate = new Intl.DateTimeFormat('sv', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: timeZone,
        hour12: false
      }).format(date); 
    return convertedDate;
}


export const shouldUse24HourFormat = (timezone) => {
    const [region, city] = timezone.split('/');
  
    // Check for North American cities (these use 12h)
    if (region === 'America') {
      const northAmericanCities = [
        'New_York',
        'Chicago',
        'Los_Angeles',
        'Toronto',
        'Vancouver',
        'Mexico_City',
        'Phoenix',
        'Denver',
        'Anchorage',
        'Halifax'
      ];
      
      return !northAmericanCities.some(name => city.includes(name));
    }
    
    // Other regions that use 12-hour format
    const twelveHourRegions = [
      'Australia',    
      'Pacific',      
      'Asia/Manila',  
      'Asia/Kolkata', 
      'Asia/Colombo', 
      'Asia/Dubai',   
      'Asia/Hong_Kong',
      'Asia/Singapore'
    ];
  
    // Check full timezone or region
    return !twelveHourRegions.includes(timezone) && 
           !twelveHourRegions.includes(region);
  }