/** @jsxImportSource @emotion/react */
import { useContext } from "react"
import { InfoSectionStyle } from "../../BarStyle.css"
import EnergyMeterIcon from '../../Icons/EnergyMeterIcon';
import { EnergyMeterStyle } from '../InfobarContent.css';
import { useQuery } from "@tanstack/react-query"
import { ExplorerContext } from "../../ExplorerContext"
import EnergyMeterGraph from "../../ProductionGraph/EnergyMeterGraph";

const EnergyMeter = ({ gatewayRequest, der, background }) => {
    
    const { loadEnergyMeterConsumtion } = useContext(ExplorerContext)

    const {data, isSuccess} = useQuery({
        queryKey: ["energymeterdata", gatewayRequest.data?.id],
        enabled: gatewayRequest.isSuccess,
        queryFn: async () => {
          return loadEnergyMeterConsumtion(der.sn)
        },
        refetchInterval: 10000
    })

    return <div css={[InfoSectionStyle, background]}>
        <h3><EnergyMeterIcon style={EnergyMeterStyle} />{der.name}</h3>
        {isSuccess && <EnergyMeterGraph
            data={data.derData.energyMeter.powerConsumption}
        />}
    </div>
    
};

export default EnergyMeter;