


export const cleanDerSn = (derSn) => {
    return derSn.replace(/^.{2}-/, '');
}





